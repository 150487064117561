import React from 'react';
import styled from '@emotion/styled';

const StyledLabel = styled.div`
  position: relative;

  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  display: ${props => props.display};
  align-self: ${props => props.alignFlex};
  border-color: ${props => props.color};
`;

const LabelTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
      display: block;
      content: '';
      flex: 10 0 0;
      height: 1px;
      border-top: 1px solid;
      border-color: ${props => props.color};
    
  }
    &:after {
      
        display: block;
        content: '';
        flex: 2 0 0;
        height: 1px;
        border-top: 1px solid;
        border-color: ${props => props.color};
      
    }
  }
`;

const LabelGap = styled.div`
  height: 26px;
  font-weight: bold;
  overflow: hidden;
  flex: 0 0 25%;
  margin-top: -18px;
  text-align: center;
`;

const LabelContents = styled.div`
  display: inline-block;
  font-weight: bold;
  color: ${props => props.color};
`;

const LabelInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: ${props => props.color};
  font-size: ${props => props.fontSize};
  color: ${props => props.fontColor};
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.font};
  @media (max-width: 550px) {
    font-size: ${props => props.mobileFontSize};
  }
`;

const Label = props => (
  <StyledLabel
    color={props.color}
    display={props.display || 'inline-block'}
    alignFlex={props.alignFlex}
  >
    <LabelTop color={props.color}>
      <LabelGap color={props.color}></LabelGap>
    </LabelTop>
    <LabelContents color={props.color}>
      <LabelInner
        color={props.color}
        fontSize={props.fontSize ? props.fontSize : '14px'}
        fontColor={props.fontColor ? props.fontColor : props.color}
        fontWeight={props.fontWeight ? props.fontWeight : 'normal'}
        font={props.font ? props.font : "'DM Sans', sans-serif"}
        mobileFontSize={props.mobileFontSize ? props.mobileFontSize : '10px'}
      >
        {props.text}
      </LabelInner>
    </LabelContents>
  </StyledLabel>
);

export default Label;
